<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-card-text class="text-center">
          <div class="d-flex flex-column justify-space-between align-center mb-4">
            <v-img
              :width="200"
              src="@/assets/images/logo.svg"
            />
            <v-progress-circular
            v-if="loading"
              class="mt-10"
              indeterminate
              :value="100"
              color="blue-grey"
            />
          </div>

          <h6 class="text--disabled font-weight-medium">
            {{ validationMessage }}
          </h6>
          <div class="mt-10" v-if="showSignin">
            <v-btn
              text
              small
              color="primary"
              to="/"
            >
              Sign In
            </v-btn>
          </div>
        </v-card-text>
        <v-snackbar
          v-model="snackbar"
          top
          color="danger"
        >
          Etwas ist schief gelaufen
          <template v-slot:action="{attrs}">
            <v-btn
              color=""
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </base-card>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    name: 'ValidateRegister',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Registrierung validieren',
    },
    data () {
      return {
        showSignin: false,
        loading: true,
        snackbar: false,
        validationMessage: ''
      }
    },

    methods: {
      validateUser () {
        axios.post(`${process.env.VUE_APP_SMT_API_URL}/user/validate`, {
          ValidationCode: this.$route.params.token,
          SendEmail: true
        }, {
          headers: {
              'SID': this.$route.params.sid
          }
        })
        .then(response => {
          this.showSignin = true;
          this.loading = false;
          this.validationMessage = 'Registrierung wurde validiert';
        })
        .catch(error => {
          this.snackbar = true;
          this.loading = false;
          this.validationMessage = 'das Konto konnte nicht validiert werden';
        });
      }
    },
    mounted() {
      this.validateUser();
    },
  }
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
